import type { MaybeRef } from '~/types/helpers'
import type { RouteLocation } from 'vue-router'
import type { ISidebarItem, ISidebarSection } from '@ui/components/Sidebar'

export type PageNavigationItem<I extends ISidebarItem = ISidebarItem> = I & {
  sectionId: string
}

export interface UseLeaseNavigationOptions {
  routeId?: (route: RouteLocation) => string | undefined
}

type ExtractSidebarItem<S extends ISidebarSection> = S['items'][number]

export const useLeaseNavigation = <
  S extends ISidebarSection = ISidebarSection,
  I extends ExtractSidebarItem<S> = ExtractSidebarItem<S>,
>(
  sections: MaybeRef<S[]>,
  options: UseLeaseNavigationOptions = {},
) => {
  const { routeId = () => undefined } = options
  const bus = useEventBus<string>('exclusiveOpen:sidebarSection')
  const _sections = computed(() => toValue(sections))

  const currentPage = computed<PageNavigationItem<I> | undefined>(() => {
    const route = useRoute('division-companyId-lease-leaseDocumentId')
    const queryId = routeId(route)

    if (queryId) {
      for (const section of _sections.value) {
        const currentItem = section.items.find((item) => item.id === queryId)
        if (currentItem) {
          return createPageNavigationItem(section, currentItem)
        }
      }
    }

    if (_sections.value.length === 0) return
    const [section] = _sections.value

    if (section.items.length === 0) return
    const [item] = section.items

    return createPageNavigationItem(section, item)
  })

  const nextPage = computed<PageNavigationItem<I> | undefined>(() => {
    const currentPageIndex = _sections.value.findIndex(
      (item) => item.id === currentPage.value?.sectionId,
    )

    if (currentPageIndex === -1) return

    const currentSection = _sections.value[currentPageIndex]
    const currentItemIndex = currentSection.items.findIndex(
      (item) => item.id === currentPage.value?.id,
    )

    if (currentItemIndex === -1) {
      return
    } else if (currentItemIndex < currentSection.items.length - 1) {
      const item = currentSection.items[currentItemIndex + 1]
      // If there is a next item in the current group
      return createPageNavigationItem(currentSection, item)
    } else if (currentPageIndex < _sections.value.length - 1) {
      // If there is a next group
      const nextGroup = _sections.value[currentPageIndex + 1]

      // Find the next non-empty page in the next group
      const nextNonEmptyPage = findNextNonEmptyPage(nextGroup)

      if (nextNonEmptyPage) {
        return nextNonEmptyPage
      } else {
        // If all pages in the next group are undefined, keep going until a non-empty page is found
        for (let i = currentPageIndex + 2; i < _sections.value.length; i++) {
          const subsequentGroup = _sections.value[i]
          const subsequentNonEmptyPage = findNextNonEmptyPage(subsequentGroup)

          if (subsequentNonEmptyPage) {
            return subsequentNonEmptyPage
          }
        }
      }
    }
  })

  function findNextNonEmptyPage(section: S): PageNavigationItem<I> | undefined {
    for (const item of section.items) {
      if (item.id !== undefined) {
        return createPageNavigationItem(section, item)
      }
    }
    return undefined
  }

  const previousPage = computed<PageNavigationItem<I> | undefined>(() => {
    const currentPageIndex = _sections.value.findIndex(
      (item) => item.id === currentPage.value?.sectionId,
    )

    if (currentPageIndex === -1) return

    const currentSection = _sections.value[currentPageIndex]
    const currentItemIndex = currentSection.items.findIndex(
      (item) => item.id === currentPage.value?.id,
    )

    if (currentItemIndex === -1) {
      return
    } else if (currentItemIndex > 0) {
      const item = currentSection.items[currentItemIndex - 1]
      // If there is a previous item in the current group
      return createPageNavigationItem(currentSection, item)
    } else if (currentPageIndex > 0) {
      // If there is a previous group
      let prevGroupIndex = currentPageIndex - 1

      // Iterate through previous groups until a non-empty page is found
      while (prevGroupIndex >= 0) {
        const prevGroup = _sections.value[prevGroupIndex]
        const lastItemIndex = prevGroup.items.length - 1

        if (
          lastItemIndex >= 0 &&
          prevGroup.items[lastItemIndex].id !== undefined
        ) {
          const item = prevGroup.items[lastItemIndex]
          // If there is a previous item in the current group
          return createPageNavigationItem(prevGroup, item)
        }

        // If the last item in the previous group is undefined, continue with the previous group
        prevGroupIndex--
      }
    }
  })
  async function exclusiveOpenGroup(page: PageNavigationItem<I>) {
    bus.emit('section-' + page.sectionId)
    const element = document.getElementById('item-' + page.id)
    setTimeout(() => {
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }, 250)
  }

  return {
    currentPage,
    nextPage,
    previousPage,
    exclusiveOpenGroup,
  }

  function createPageNavigationItem(
    section: ISidebarSection,
    item: ISidebarItem,
  ): PageNavigationItem<I> {
    return {
      ...item,
      sectionId: section.id,
    } as PageNavigationItem<I>
  }
}
